<template>
  <div class="productDemonstration">
    <div class="left">
      <p>角膜接触镜介绍</p>
      <div @click="idx=0" :class="idx==0?'xz':''"><i class="el-icon-caret-right"></i>原理</div>
      <div @click="idx=1" :class="idx==1?'xz':''"><i class="el-icon-caret-right" ></i>设计</div>
      <div @click="idx=2" :class="idx==2?'xz':''"><i class="el-icon-caret-right" ></i>材料</div>
      <div @click="idx=3" :class="idx==3?'xz':''"><i class="el-icon-caret-right"></i>SCL</div>
      <div @click="idx=4" :class="idx==4?'xz':''"><i class="el-icon-caret-right" ></i>RGP</div>
      <div @click="idx=5" :class="idx==5?'xz':''"><i class="el-icon-caret-right" ></i>OK镜</div>
    </div>
    <div class="right" v-if="idx == 0">
      <div class="imgbox" @click="go('CLVideo_yl')">
        <img src="@/assets/img/conrea/Contact_principle_s.jpeg" alt="">
        <p>OK镜原理</p>
      </div>
    </div>
    <div class="right" v-if="idx == 1">
      <div class="imgbox">
        <img src="@/assets/img/conrea/Contact_design_s.jpeg" alt="">
        <p>OK镜设计1</p>
      </div>
      <div class="imgbox pd-1" style="margin: 0 40px;">
        <img src="@/assets/img/conrea/Contact_design_s2.jpeg" alt="">
        <p>普通RGP和圆锥角膜RGP对比设计</p>
      </div>
      <div class="imgbox pd-1" @click="go('Vedio_OKLensDesignPrinciple')">
        <img src="@/assets/img/sj3.jpeg" alt="">
        <p>OK镜设计2</p>
      </div>
    </div>
    <div class="right" v-if="idx == 2">
      <div class="imgbox" @click="go('CLVideo_cl')">
        <img src="@/assets/img/conrea/Contact_material_s.jpeg" alt="">
        <p>材料</p>
      </div>
    </div>
    <div class="right" v-if="idx == 3">
      软性角膜接触镜，简称软镜或SCL，是目前临床上最普及的角膜接触镜，由柔软吸水的塑胶聚合物材料制成，其特点就是材料的柔软性使得镜片有良好的可塑性，具备相当的透氧以保证佩戴期间的角膜生理需求。正规软镜的设计、材料特征是根据人眼部解剖和生理、屈光矫正的需求而确定，科学验配是保证健康、安全舒适的前提。
    </div>
    <div class="right" v-if="idx == 4">
      硬性透气性角膜接触镜（Rigid Gas Permeable contact lens ,RGPCL）的简称是RGP。RGP所含的硅、氟等聚合物，能够大大增加氧气的通过量，俗称“会呼吸的隐形眼镜”。RGP具有良好的湿润性和抗沉淀性。由于其材料的透氧性和独特的光学性能，使得其具有并发证少、矫正视觉效果佳的特征！尤其适合一些特殊的屈光不正患者，如高度散光、圆锥角膜等，这些屈光不正患者戴用普通的框架和软性无法达到良好的矫正效果，而RGP则是理想的选择。特点：高透氧，更健康；成型性好，光学矫正质量高；保养简单，正常可使用1年半。
    </div>
    <div class="right" v-if="idx == 5">
      角膜塑形镜（orthokeratology）简称OK镜：是通过配戴特殊设计的与角膜前表面相逆的硬性透气性接触镜，镜片中央区平坦、旁中央区陡峭，以重塑角膜形态，使角膜中央区弧度在一定范围内出现平坦和规则样改变，从而暂时性降低近视屈光度数，提高裸眼视力的可逆性非手术物理矫形治疗方法，其同时可获得一定的减缓近视眼发展的临床效果。特点：暂时减少近视屈光度，延缓近视发展
    </div>

    <el-dialog
        title="视频播放"
        :visible.sync="show"
        width="80%"
        center>
      <video :src="src" autoplay="autoplay"  controls="controls" class="vid"></video>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "productDemonstration",
  data() {
    return {
      idx: 0,
      show: false,
      src: ''
    }
  },
  methods: {
    go(key) {
       this.src = this._getUrl(key)
      // console.log(this.src)
      this.show = true
    }
  }
}
</script>

<style scoped lang="scss">
.productDemonstration {
  width: 100%;
  display: flex;
  justify-content: start;
}
.left {
  padding-top: 10vh;
  padding-left: 8vw;
  text-align: left;
  p {font-size: 17px;}
  div {
    padding: .2rem 0;
    border-bottom: 1px solid #000000;
    cursor: pointer;
    &:hover {
      background: #efefef;
    }
  }
  .xz {background: #efefef}
}
.right {
  display: flex;
  justify-content: space-between;
  width: 50vw;
  height: 60vh;
  padding-left: 10vw;
  padding-top: 11vh;
  overflow: hidden;
  img {
    display: block;
    width: 100%;
  }
  .imgbox {
    cursor: pointer;
    width: 40%;
    p {width: 100%; padding-top: .1rem}
  }
}
.vid {
  width: 60vw;
  margin: 0 auto;
  display: block;
}
</style>
